/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    img: "img",
    strong: "strong",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Zbrojenie do murów Czamaninek Typ I zostało zaprojektowane i gruntownie przetestowane w celu wzmocnienia ciepłych ścian murowanych na naprężenia rozciągające powstałe w wyniku lokalnych skoków obciążeń. Ryzyko zarysowania ściany występuje przede wszystkim pod oknami, ponieważ są to strefy poddane dużo mniejszym obciążeniom niż filarki międzyokienne zlokalizowane bezpośrednio przy nich."), "\n", React.createElement(_components.p, null, "Typowe zbrojenie do murów Czamaninek charakteryzuje się wysoką wytrzymałością na rozciąganie, dzięki czemu skutecznie przenosi naprężenia rozciągające i eliminuje ryzyko powstawania zarysowań w strefie podokiennej. Jednocześnie jest bardzo łatwe w układaniu, a dołączana do adaptacji projektu przez nas krótka instrukcja dokładnie określa, jak należy lokalizować zbrojenie w murze. Tabela oferowanych przez nas zbrojeń dla różnego typu konstrukcji murowych została przedstawiona poniżej."), "\n", React.createElement(_components.p, null, "Do ścian z linii AKU stosuje się odpowiednio siatki Typ I o wysokości 200 mm dla ścian o grubości 240 mm, jak np. AKU K5 i AKU K4 oraz siatki Typ I o wysokości 140 mm dla ścian o grubości 180 mm, jak np. AKU K3 oraz AKU K2. Zalecana grubość spoiny nie powinna być mniejsza niż 8 mm. Zbrojenie do murów Czamaninek należy stosować w każdej spoinie poziomej znajdującej się pod otworem okiennym w murach ścian naziemnych. Wysunięcie zbrojenia powinno wynosić 80 cm poza krawędź otworu po obu jego stronach. Dla otworów o szerokości powyżej 250 cm siatka powinna sięgać na 80 cm od krawędzi otworu w obu kierunkach."), "\n", React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "/img/zbrojenie-murow-rys-2.png",
    alt: "Rysunek siatki Typ I średnice D1 = D2 = 30 mm"
  })), "\n", React.createElement(_components.p, null, "Rys. 2 Rysunek siatki wraz z oznaczeniami – dla siatki Typ I średnice D1 = D2 = 30 mm"), "\n", React.createElement("br"), "\n", React.createElement("h3", {
    class: "justify"
  }, " ZBROJENIE DO MURU TYP I (długość 1,5 - 2,5 m)"), "\n", React.createElement("table", {
    class: "table-section justify"
  }, React.createElement("tr", null, React.createElement("th", {
    colspan: "3"
  }, "TYP I")), React.createElement("tr", null, React.createElement("td", null, "**WYSOKOŚĆ**"), React.createElement("td", null, "**ILOŚĆ SZTUK NA PALECIE**"), React.createElement("td", null, "**L - DŁUGOŚĆ**")), React.createElement("tr", null, React.createElement("td", null, "140 "), React.createElement("td", null, "750/50 "), React.createElement("td", null, "1,5 - 2,5  ")), React.createElement("tr", null, React.createElement("td", null, "200 "), React.createElement("td", null, "500/50  "), React.createElement("td", null, "1,5 - 2,5  "))), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Uwagi:"), "\nZbrojenie stosuje się przy murowaniu na zaprawie w poziomej spoinie muru. Produkt składa się z dwóch prostych prętów połączonych spawaną doczołowo poprzeczką. Zaletą jest zmienność zakresu szerokości od 30 mm do 240 mm oraz różne średnice zgodnie z życzeniem klienta."), "\n", React.createElement(_components.p, null, "Sposób zabezpieczenia strefy przyokiennej przed powstawaniem zarysowań za pomocą zbrojenia w spoinach"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "przy otworze mniejszym od 250 cm"), "\n"), "\n", React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "/img/zbrojenie-murow-do-250-cm.png",
    alt: "Zabezpieczenie przy otworze mniejszym od 250 cm"
  })), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "przy otworze większym od 250 cm"), "\n"), "\n", React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "/img/zbrojenie-murow-powyzej-250-cm.png",
    alt: "Zabezpieczenie przy otworze większym od 250 cm"
  })));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
